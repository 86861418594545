import { useRef, useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import NavBar from './Components/NavBar';

import HeroSection from './sections/1_HeroSection.js';
import ValueProposition from './sections/2_ValueProposition.js';
import SignupSection from './sections/3_SignupSection.js'
import FullFeatures from './sections/4_FullFeatures.js';
import CaseStudy from './sections/5_CaseStudy.js';
import AboutTwoDot from './sections/6_AboutTwoDot.js';
import OurBackers from './sections/7_OurBackers.js';
import SignupSectionBottom from './sections/8_SignupSectionBottom.js';
import Footer from './sections/10_Footer.js';

import { Container, Divider, Stack } from '@mui/material';
import EarlyAccessFormDialog from './Components/EarlyAccessFormDialog.js';
import FAQ from './sections/9_FAQ.js';

function App() {
    const heroRef = useRef(null);
    const valuePropRef = useRef(null);
    const topSignupSectionRef = useRef(null);
    const fullFeaturesRef = useRef(null);
    const caseStudyRef = useRef(null);
    const aboutTDRef = useRef(null);
    const backersRef = useRef(null);
    const signupBottomRef = useRef(null);
    const footerRef = useRef(null);

    const [logoColour, setLogoColour] = useState("pink");

    const [bookingDialogOpen, setBookingDialogOpen] = useState(false);

    // Detect when the element is in view
    useEffect(() => {
        const handleScroll = () => {
            const refs = [
            	{ ref: heroRef, colour: "clear" },
            	{ ref: valuePropRef, colour: "pink" },
            	{ ref: topSignupSectionRef, colour: "white" },
            	{ ref: fullFeaturesRef, colour: "pink" },
            	{ ref: caseStudyRef, colour: "white" },
            	{ ref: aboutTDRef, colour: "pink" },
            	{ ref: backersRef, colour: "pink" },
            	{ ref: signupBottomRef, colour: "pink" },
            	{ ref: footerRef, colour: "white" },
            ];

            // Iterate through refs to find the one currently passing the top
            let colorToSet = "pink"; // Default to clear
            for (const { ref, colour } of refs) {
            	if (ref.current) {
            		const rect = ref.current.getBoundingClientRect();
            		if (rect.top <= 64 && rect.bottom > 64) {
            			colorToSet = colour;
            			break;
            		}
            	}
            }
            setLogoColour(colorToSet);
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Trigger initial scroll detection
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    function openBookingDialog() {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSdy5ZMdXUkuq5ro7lBOFJvAOyZQZvYmlvCVMgF4l6FYMt6qqQ/viewform', '_blank').focus()
        // setBookingDialogOpen(true)
    }

    return (
        <ThemeProvider theme={theme}>
            <NavBar
                logoColour={logoColour}
                scrollTargetRef={heroRef}
                openBookingDialog={openBookingDialog}
            />

            <EarlyAccessFormDialog
                isOpen={bookingDialogOpen}
                closeDialog={() => setBookingDialogOpen(false)}
            />

            <Stack>
                <HeroSection scrollTargetRef={valuePropRef} sectionRef={heroRef} />
                <Divider variant='middle' />
                <ValueProposition sectionRef={valuePropRef} />
                <SignupSection sectionRef={topSignupSectionRef} openBookingDialog={openBookingDialog} />
                {/* <FullFeatures sectionRef={fullFeaturesRef}/> */}
                {/* <Divider variant='middle'/> */}
                {/* <CaseStudy sectionRef={caseStudyRef} /> */}
                {/* <AboutTwoDot sectionRef={aboutTDRef} />
                <OurBackers sectionRef={backersRef} /> */}
                {/* <Divider variant='middle' /> */}
                <FAQ sectionRef={fullFeaturesRef}/> {/* TODO: replace this with a FAQ ref and stop being lazy lol */}
                <Divider variant='middle' />
                <SignupSectionBottom sectionRef={signupBottomRef} openBookingDialog={openBookingDialog} />
                <Footer sectionRef={footerRef} pageTopRef={heroRef} />
            </Stack>
        </ThemeProvider>
    );
}

export default App;
