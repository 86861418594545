import { Box, Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import backgroundSVG from '../assets/logos/twodot-lander.svg';
// import backgroundSVG from '../assets/2dotshrunk.svg';


const HeroSection = ({ scrollTargetRef, sectionRef }) => {
    return (


        <Box
            ref={sectionRef}
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                boxSizing: 'border-box',
                paddingX: 4,
                paddingTop: { xs: 16, md: 4, lg: 4 },
                paddingBottom: 2,
                backgroundImage: `url(${backgroundSVG})`,
                backgroundSize: '100% auto',
                backgroundRepeat: 'no-repeat',
                position: 'relative',


                // Dynamic background position
                backgroundPosition: {
                    xs: 'center calc(105%)', // More offset on smaller screens
                    sm: 'center calc(110% + 1px)',
                    md: 'center calc(105%)',
                    lg: 'center 100%', // Centered for larger screens
                },
            }}
        >
            <Stack
                sx={{
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    textAlign: 'center',
                    width: '100%',
                    height: '80vh',
                    mt: 10,
                }}
            >
                <Box>
                    <Typography variant="h2" fontSize={'8.0rem'}>
                        Become the go-to recruiter in your niche
                    </Typography>
                    <Typography variant="body2" fontSize={'2.0rem'}>
                        The simple system to build your influence on LinkedIn with just 10 minutes a day.
                    </Typography>
                </Box>

                <Box>
                    <Button size="large" color="primary" sx={{ width: '100%' }} variant="contained">
                        Join the waitlist
                    </Button>
                </Box>
            </Stack>

            <Typography
                variant="subtitle2"
                sx={{
                    fontSize: '16px',
                    letterSpacing: '0.1em',
                    textAlign: 'center',
                    cursor: 'pointer',
                    position: 'absolute',
                    bottom: 16, // Place the "See More" text 16px from the bottom of the parent container
                    left: '50%', // Position horizontally in the center
                    transform: 'translateX(-50%)', // Offset to exactly center the text
                }}
                onClick={() => scrollTargetRef.current?.scrollIntoView({ behavior: 'smooth' })}
            >
                See More ↓
            </Typography>
        </Box>

    )
}

export default HeroSection;