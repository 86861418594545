import { Box, Button, Divider, FormControl, Grid2 as Grid, Stack, TextField, Typography, useTheme } from "@mui/material";
import WaitlistForm from '../Components/WaitlistForm';

import two_dot_white_dots from '../assets/logos/two_dot_white_dots.png'
import two_dot_white_logo from '../assets/logos/Two_Dot_white.png'
import linkedinLogo from '../assets/icons/linkedin_white.png'
import instagramLogo from '../assets/icons/instagram_white.png'


const Footer = ({ sectionRef, pageTopRef }) => {
    const theme = useTheme();

    const listStyle = {
        marginTop: 16,
        listStylePosition: 'inside',
        // listStyleType: 'disc',
        fontSize: '1.2rem',
        fontWeight: '200',
        fontFamily: 'Prompt',
        lineHeight: '1.5em',
        color: '#F0ECEC',
        // letterSpacing: '0.1em',
    }

    return (
        <Stack sx={{ background: theme.palette.primary.main, minHeight: '50vh' }} paddingX={{ xs: 2, sm: 4, md: 16 }} paddingY={8} ref={sectionRef}>
            <Stack sx={{ justifyContent: 'space-between', alignItems: 'center' }} spacing={4} direction={"row"} marginY={8} paddingX={8}>
                <img src={two_dot_white_logo} width={128} />
                <img src={two_dot_white_dots} width={64} height={64} />
            </Stack>
            <Divider sx={{ background: 'white', marginY: 4 }} />

            {/* <Grid container spacing={2} paddingTop={4} paddingBottom={16}>
                <Grid size={{ xs: 6, sm: 2 }}>
                    <Typography variant="h5" color="white">Company</Typography>
                    <ul style={listStyle}>
                        <li>Home</li>
                        <li>What we do</li>
                        <li>Work</li>
                        <li>Method</li>
                        <li>About</li>
                    </ul>
                </Grid>
                <Grid size={{ xs: 6, sm: 2 }}>
                    <Typography variant="h5" color="white">Discover</Typography>
                    <ul style={listStyle}>
                        <li>Engagements</li>
                        <li>VisionCamp</li>
                        <li>Speaking</li>
                        <li>Our Book</li>
                        <li>Shop</li>
                    </ul>
                </Grid>
                <Grid size={{ xs: 6, sm: 2 }}>
                    <Typography variant="h5" color="white">Learn</Typography>
                    <Box>
                        <ul style={listStyle}>
                            <li>Articles</li>
                            <li>Press & Media</li>
                            <li>Testimonials</li>
                            <li>FAQ</li>
                            <li>Careers</li>
                        </ul>
                    </Box>
                </Grid>
                <Grid size={{ xs: 6, sm: 1 }}></Grid>
                <Grid size={{ xs: 12, sm: 5 }}>
                    <form
                        noValidate
                        autoComplete="off"
                        // onSubmit={handleSubmit}
                        style={{ width: "100%" }}
                    >
                        <FormControl sx={{ width: "100%" }}>
                            <Stack sx={{ width: "100%" }} spacing={2}>
                                <Typography variant="h5" color="white" sx={{ width: '34ch' }}>Get valuable strategy, culture, and brand insights straight to your inbox.</Typography>
                                <TextField
                                    size="small"
                                    label="Email"
                                    variant="standard"
                                    color="white"
                                    focused
                                    fullWidth
                                    type="email"
                                    required
                                    sx={{ color: 'white', }}
                                // value={email}
                                />
                                <Button
                                    size="small"
                                    variant="contained"
                                    // fullWidth
                                    color="white"
                                    type="submit"
                                // disabled={submitting}
                                >
                                    {submitting ? "Submitting..." : "Join Waitlist"}
                                    Join Waitlist
                                </Button>
                                <Typography variant="body2">By signing up to receive emails from Two Dot, you agree to our <a href="#">Privacy Policy</a>. We treat your info responsibly. Unsubscribe anytime.</Typography>
                            </Stack>
                        </FormControl>
                    </form>
                </Grid>
            </Grid> */}

            <Grid container>
                <Grid size={{xs: 12, sm: 12, md: 4 }} sx={{display: 'flex', justifyContent: 'center'}}>
                    <Typography variant="subtitle2" color="white">© 2025 - Two Dot Group Ltd.</Typography>
                </Grid>
                <Grid size={{xs: 12, sm: 12, md: 4 }} sx={{display: 'flex', justifyContent: 'center'}}>
                    <a href="https://linkedin.com/company/twodotco/" target="_blank" rel="noreferrer">
                        <img src={linkedinLogo} width={32} height={32} />
                    </a>
                </Grid>
                <Grid size={{xs: 12, sm: 12, md: 4 }} sx={{display: 'flex', justifyContent: 'center'}}>
                    <Typography variant="subtitle2" color="white" onClick={() => pageTopRef.current?.scrollIntoView({ behavior: 'smooth' })}
                        sx={{ cursor: 'pointer' }}
                    >
                        Back to top ↑
                    </Typography>
                </Grid>
            </Grid>
        </Stack>
    )
}

export default Footer;