import theme from '../theme';
import {
    AppBar,
    Box,
    Divider,
    Typography,
    useMediaQuery,
    Toolbar,
    IconButton,
    useScrollTrigger,
    Slide
} from '@mui/material';

import TwoDotLogoPink from '../assets/logos/Two_Dot_pink.png';
import TwoDotLogoWhite from '../assets/logos/Two_Dot_white.png';


function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    const navBarShouldHide = useMediaQuery(theme.breakpoints.down('md'));

    if (navBarShouldHide)

        return (
            <Slide appear={false} direction="down" in={!trigger}>
                {children ?? <div />}
            </Slide>
        );
    else return children;
}

const NavBar = ({ logoColour, scrollTargetRef, openBookingDialog }) => {
    const pinTwoDotLogo = useMediaQuery(theme.breakpoints.between('xs', 'md'));
    console.log("pinTwoDotLogo", pinTwoDotLogo);

    return (
        <HideOnScroll>
            <AppBar sx={{ zIndex: 10, backgroundColor: { xs: '#fff', sm: '#fff', md: 'transparent' }, boxShadow: 0, pb: 1 }}>

                {/* <AppBar
                // position="sticky"
                sx={{
                    backgroundColor: { xs: '#fff', sm: '#fff', md: 'transparent' },
                    boxShadow: { xs: 1, sm: 1, md: 0 },
                    padding: '1rem 2rem',
                    // borderBottom: { xs: '1px solid lightgray', sm: '1px solid lightgray', md: 'none' }
                }}
            > */}
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        onClick={() => scrollTargetRef.current?.scrollIntoView({ behavior: 'smooth' })}
                        sx={{ cursor: 'pointer' }}
                    >
                        {pinTwoDotLogo ? (
                            <img src={TwoDotLogoPink} height={64} />
                        ) : (
                            logoColour === "clear" ? (
                                <Box sx={{ height: 64 }}></Box>
                            ) : logoColour === "pink" ? (
                                <img src={TwoDotLogoPink} height={64} />
                            ) : logoColour === "white" ? (
                                <img src={TwoDotLogoWhite} height={64} />
                            ) : null
                        )}
                    </Box>

                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <a href='/#book-demo' onClick={openBookingDialog}>
                            <Typography variant="body2">Join the waitlist</Typography>
                        </a>
                        {/* <a href={`${process.env.REACT_APP_URL}/sign-up`}>
                            <Typography variant="body2">Sign up</Typography>
                        </a> */}
                    </Box>
                </Toolbar>
            </AppBar>
        </HideOnScroll>
    );
};

export default NavBar;
