import { Box, Card, Container, Divider, Stack, Typography, useTheme } from "@mui/material"
import Grid from '@mui/material/Grid2';
import WaitlistForm from '../Components/WaitlistForm';
import theme from "../theme";
import deviceMockups from '../assets/supporting_images/device_mockups.png'


const ListItem = ({ children }) => {
    const theme = useTheme();

    return (
        <li>
            <Typography
                variant="body1"
                component="span"
            >
                {children}
            </Typography>
        </li>
    )
};

const GriddedCard = ({ children }) => (
    <Grid size={{ sm: 12, md: 12, md: 4 }}>
        <Card sx={{ p: 2, minHeight: { sm: '', md: '300px', lg: '200px' }, display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1">
                {children}
            </Typography>
        </Card>
    </Grid>
)

const ValueProposition = ({ sectionRef }) => {
    const listStyle = {
        listStylePosition: 'outside',
        listStyleType: 'disc',
        fontWeight: '300',
        fontFamily: 'Prompt',
        lineHeight: 'normal',

        '> li': {
            marginBottom: '16px', // Adjusts the space between list items
        }
    }

    return (
        <Container maxWidth="xl" sx={{ p: 2 }}>
            <Grid
                container
                paddingX={{ xs: 2, sm: 4, md: 16 }}
                paddingY={8}
                ref={sectionRef}
                columnSpacing={4}
                rowSpacing={8}
            >
                <Grid size={12} sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography
                        variant="h3"
                        marginY={4}
                        // width={{ sm: '100%', md: '70%' }}
                        fontWeight={400}
                    >
                        Most recruiters want to build a brand — but between BD, delivery, and admin, it never happens.&nbsp;
                        <Typography component={'span'} variant="h3" fontWeight={600} color='primary'>
                            Two Dot makes it effortless.
                        </Typography>
                    </Typography>
                </Grid>

                <Grid size={{ xs: 12, md: 4 }}>
                    <img src={deviceMockups} style={{ maxWidth: '100%' }} />
                </Grid>

                <Grid size={{ xs: 12, md: 8 }}  >
                    <Box sx={{ width: '100%' }}>
                        <ul style={listStyle}>
                            <ListItem>
                                <Typography variant="body1" component={'span'} fontWeight={600}>See where you stand</Typography> with your personalised Influence Score
                            </ListItem>
                            <ListItem>
                                <Typography variant="body1" component={'span'} fontWeight={600}>Compare Your Score</Typography> via a Company Leaderboard and Industry Benchmarks
                            </ListItem>
                            <ListItem>
                                <Typography variant="body1" component={'span'} fontWeight={600}>Know what to do next</Typography> with 3 Daily Actions that take just 10 minutes a day that build your influence.
                            </ListItem>
                        </ul>
                    </Box>
                </Grid>
                <Grid container spacing={4}>
                    <GriddedCard>
                        ✅ <Typography variant='body1' component={'span'} fontWeight={600}>Built for Recruiters</Typography>, by recruitment founders and technologists that understand your needs.
                    </GriddedCard>
                    <GriddedCard>
                        ✅ <Typography variant='body1' component={'span'} fontWeight={600}>Backed by behavioural psychology</Typography> - designed to build how others perceive your influence.
                    </GriddedCard>
                    <GriddedCard>
                        ✅ <Typography variant='body1' component={'span'} fontWeight={600}>Low-effort, high-impact</Typography> - no content calendar or overthinking required.
                    </GriddedCard>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ValueProposition;