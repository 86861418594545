// import { Accordion, AccordionDetails, AccordionSummary, Grid as Grid, Typography, useTheme } from "@mui/material";
// import RecentTaskList from '../Components/RecentTaskList';

// export default function FAQ() {

//     const theme = useTheme();

//     return (
// <Grid container>
//     <Grid size={4}>
//         <Typography variant="subtitle1" color="text.secondary">
//             Can't find what you're looking for?
//         </Typography>
//         <Typography variant="h3">
//             We'd love to chat with you.
//         </Typography>
//     </Grid>
//     <Grid size={8}>
//         <Accordion defaultExpanded>
//             <AccordionSummary
//                 aria-controls="panel1-content"
//                 id="panel1-header"
//             >
//                 <Typography component="span" variant="body2">Recent Activities</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//                 <RecentTaskList />
//             </AccordionDetails>
//         </Accordion>
//     </Grid>
// </Grid>
//     )
// }

import { Box, Typography, Card, CardContent, Collapse, IconButton, TextField, InputAdornment, Grid2 as Grid, Stack, Container } from '@mui/material';
import { Search, ExpandMore, ChatBubbleOutline } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const faqData = [
    {
        title: "Do I need to be posting on LinkedIn already?",
        content: "Nope. Two Dot meets you where you are. Whether you post daily or haven't started yet, we'll guide you step by step to build influence fast — without overthinking content."
    },
    {
        title: "I don't have time — will this just be another thing on my to-do list?",
        content: "Two Dot is designed for recruiters who are already stretched. It takes under 10 minutes a day, and you'll know exactly what to do — no planning, no fluff, just action."
    },
    {
        title: "What exactly is the Influence Score based on?",
        content: "It's made up of **7 key traits** of perceived influence that drive buying decisions: visibility, credibility, relatability, engagement, network quality, consistency, and conversion readiness. You'll get a full breakdown — plus tips to improve each one."
    },
    {
        title: "Is this just for top billers or personal branding experts?",
        content: "No — it's built for ambitious recruiters at all levels, especially those who are trying to grow their brand but don't know where to start or what's working."
    },
    {
        title: "Why is it free? What's the catch?",
        content: "No catch. This is the free beta for our first 500 users. You get lifetime access. We just ask for feedback as we grow. Later, it'll be a paid product — but you'll be ahead of the curve."
    },
    {
        title: "Does it work if I'm not in a 360 role?",
        content: "It's most valuable for 360 or BD-heavy recruiters who need visibility and trust to generate leads — but any recruiter who wants to stand out can benefit."
    }
];

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: "none",
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
        transform: "rotate(90deg)",
    },
    [`& .${accordionSummaryClasses.content}`]: {
        marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles("dark", {
        backgroundColor: "rgba(255, 255, 255, .05)",
    }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function RecentTaskList({ sectionRef }) {
    return (
        <Container maxWidth='xl' sx={{ py: 8 }}>
            <Stack spacing={{md: 4, md: 8}}>
                <Typography variant='h2' maxWidth={'20ch'}>
                    Frequently Asked Questions
                </Typography>
                <Grid ref={sectionRef} container spacing={2}>
                    {/* Left Side */}
                    <Grid size={{ xs: 12, sm: 5, md: 4 }}>
                        <Stack spacing={2}>
                            <Typography variant="subtitle1" sx={{ color: "gray", mb: 1 }}>Can't find what you are looking for?</Typography>
                            <Typography variant="h5">Lets chat!</Typography>
                            <Stack direction='row' spacing={2} alignItems={'center'}>
                                <ChatBubbleOutline sx={{ fontSize: 60, color: "#007bff" }} />
                                <Typography><a href='mailto:hello@twodot.co'>info@twodot.co</a></Typography>
                            </Stack>
                        </Stack>
                    </Grid>

                    {/* Right Side */}
                    <Grid size={{ xs: 12, sm: 7, md: 8 }}>
                        {faqData.map((item, index) => (
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Typography variant="h6">{item.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body2" color="text.secondary">
                                        {item.content}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                </Grid>
            </Stack>
        </Container>
    );
};